import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters({
      queryData: "projAbbottAnd/queryData",
      couponType: "projAbbottAnd/couponType",
      redeemData: "projAbbottAnd/redeemData",
      user: "liffAuth/user",
    }),
  },
  methods: {
    ...mapActions('projAbbottAnd', ['setIsMember', 'setIsBindStore', 'setThanksMessage']),
    async joinChannel(channel) {
      try {
        await abbottAndApi.joinChannel(
          this.$route.params.orgCode,
          {
            channel: channel,
            brand: this.queryData.brand,
            coupon_code: this.couponType == 'ipd' ? "IPD_001" : this.queryData.coupon_code
          }
        );
      } catch (e) {
        this.setThanksMessage("綁定錯誤");
        this.$router.push({
          name: "LiffProjAbbottAndApplyFailed",
        });
      }
    },
    formatQueryData() {
      let query = this.queryData;
      switch (this.couponType) {
        case "ipd":
          query.coupon_code = "IPD_001";
          break;
        case "j5":
        case "j5-1":
          query.coupon_code = "WI_001";
          break;
        default:
          break;
      }
      return query;
    },
    async checkStoreCode() {
      try {
        const { data } = await abbottAndApi.checkStoreCode(
          this.$route.params.orgCode,
          {
            store_code: this.queryData.store_code,
          }
        );
        return data.data.is_exist;
      } catch (e) {
        this.setThanksMessage("店家代碼錯誤");
        this.$router.push({
          name: "LiffProjAbbottAndApplyFailed",
        });
        return false;
      }
    },
    async validate() {
      switch (this.couponType) {
        case "j4":
          return this.checkStoreCode();
        default:
          return true;
      }
    },
    async submit() {
      if (!this.validate()) {
        this.setThanksMessage("不符合活動資格");
        this.$router.push({
          name: "LiffProjAbbottAndApplyFailed",
        });
        return;
      }
      const query = this.formatQueryData();
      try {
        await abbottAndApi.sendCoupon(this.$route.params.orgCode, {
          ...query,
        });
        await abbottAndApi.registerCustomer(this.$route.params.orgCode, {});
        this.setThanksMessage("成功領取");
        this.$router.push({
          name: "LiffProjAbbottAndThanks",
        });
      } catch (e) {
        this.setThanksMessage(e.response.data.message);
        this.$router.push({
          name: "LiffProjAbbottAndApplyFailed",
        });
      }
    },
    async checkAndMember() {
      try {
        const res = await abbottAndApi.checkMember(
          this.$route.params.orgCode,
          {}
        );

        this.setIsMember(res.data.is_member)

        if (!res.data.is_member) {
          await abbottAndApi.registerMember(this.$route.params.orgCode, {
            store_code: this.queryData.store_code,
            source: this.queryData.source,
          });
        }
      } catch (e) {
        this.setThanksMessage(e.response.data.message);
        this.$router.push({
          name: "LiffProjAbbottAndApplyFailed",
        });
      }
    },
    async checkMember() {
      switch (this.couponType) {
        case "ipd":
          try {
            const { data } = await abbottAndApi.checkIsSendable(
              this.$route.params.orgCode,
              {
                coupon_code: this.queryData.coupon_code ?? 'IPD_001',
                qrcode: this.queryData.qrcode,
                event_name: this.queryData.event_name,
                brand: this.queryData.brand,
              }
            );

            if (data.data.is_sendable != 1) {
              this.setThanksMessage("已領取過");
              this.$router.push({
                name: "LiffProjAbbottAndApplyFailed",
              });
              return;
            }

            const res = await abbottAndApi.checkMember(
              this.$route.params.orgCode,
              {}
            );

            this.setIsMember(res.data.is_member)
            this.setIsBindStore(res.data.is_bind_store)

            if (!res.data.is_member) {
              this.$router.push({
                name: "LiffRegisterIndex",
                params: {
                  orgCode: this.$route.params.orgCode,
                },
                query: {
                  redirect_action:
                    this.$route.query.redirect_action ??
                    "proj.abbott_and.branch_picker",
                  redirect_button: this.$route.query.redirect_button ?? 1,
                  success_button: this.$route.query.success_button ?? 0,
                },
              });
            } else {
              await abbottAndApi.registerCustomer(this.$route.params.orgCode);

              this.$router.push({
                name: "LiffProjAbbottAndStorePicker",
              });
            }
          } catch (e) {
            this.setThanksMessage(e.response.data.message);
            this.$router.push({
              name: "LiffProjAbbottAndApplyFailed",
            });
          }
          break;
        case "j4":
        try {
          const res = await abbottAndApi.checkMember(this.$route.params.orgCode, {});
          this.setIsMember(res.data.is_member)
          this.setIsBindStore(res.data.is_bind_store)

          if (!res.data.is_member) {
            this.$router.push({
              name: "LiffRegisterIndex",
              params: {
                orgCode: this.$route.params.orgCode,
              },
            });
          } else {
            await abbottAndApi.registerCustomer(this.$route.params.orgCode, {});

            const res = await abbottAndApi.checkMember(this.$route.params.orgCode,{});

            this.setIsMember(res.data.is_member)
            this.setIsBindStore(res.data.is_bind_store)

            this.$router.push({
              name: "LiffProjAbbottAndSendCoupon",
            });
          }
        } catch (e) {
          this.setThanksMessage(e.response.data.message);
          this.$router.push({
            name: "LiffProjAbbottAndApplyFailed",
          });
        }
        break;
        case "j5":
        case "j5-1":
          try {
            const res = await abbottAndApi.checkMember(this.$route.params.orgCode, {});
            this.setIsMember(res.data.is_member)
            this.setIsBindStore(res.data.is_bind_store)

            if (!res.data.is_member) {
              this.$router.push({
                name: "LiffRegisterIndex",
                params: {
                  orgCode: this.$route.params.orgCode,
                },
                query: {
                  redirect_action:
                    this.$route.query.redirect_action ??
                    "proj.abbott_and.branch_picker",
                  redirect_button: this.$route.query.redirect_button ?? 1,
                  success_button: this.$route.query.success_button ?? 0,
                },
              });
            } else {
              await abbottAndApi.registerCustomer(this.$route.params.orgCode, {});

              const res = await abbottAndApi.checkMember(this.$route.params.orgCode,{});

              this.setIsMember(res.data.is_member)
              this.setIsBindStore(res.data.is_bind_store)

              // if (res.data.is_bind_store) {
              //   this.$router.push({
              //     name: "LiffProjAbbottAndSendCoupon",
              //   });
              // } else {
              // this.$router.push({
              //   name: "LiffProjAbbottAndBranchPicker",
              // });
              // }
              this.$router.push({
                name: "LiffRegisterIndex",
                params: {
                  orgCode: this.$route.params.orgCode,
                },
                query: {
                  redirect_action:
                    this.$route.query.redirect_action ??
                    "proj.abbott_and.branch_picker",
                  redirect_button: this.$route.query.redirect_button ?? 1,
                  success_button: this.$route.query.success_button ?? 0,
                },
              });
            }
          } catch (e) {
            this.setThanksMessage(e.response.data.message);
            this.$router.push({
              name: "LiffProjAbbottAndApplyFailed",
            });
          }
          break;
        default:
          try {
            const res = await abbottAndApi.checkMember(
              this.$route.params.orgCode,
              {
                phone: this.queryData.phone,
              }
            );

            this.setIsMember(res.data.is_member)
            this.setIsBindStore(res.data.is_bind_store)

            if (res.data.is_member) {
              if (res.data.is_same_line_id) {
                this.$router.push({
                  name: "LiffProjAbbottAndSendCoupon",
                });
              } else {
                this.setThanksMessage("此手機與查詢會員不一致，請聯絡客服人員");
                this.$router.push({
                  name: "LiffProjAbbottAndApplyFailed",
                });
              }
            } else {
              this.$router.push({
                name: "LiffProjAbbottAndSendCoupon",
              });
            }
          } catch (e) {
            this.setThanksMessage(e.response.data.message);
            this.$router.push({
              name: "LiffProjAbbottAndApplyFailed",
              query: {
                messages: e.response.data.message,
              },
            });
          }
          break;
      }
    },
    async confirmCoupon() {
      try {
        let storeCode = this.redeemData.store_code
        if (!this.redeemData.store_code) {
          const data = await abbottAndApi.checkMember(
            this.$route.params.orgCode,
            {}
          );
          storeCode = data.data.store_code
        }

        const res = await abbottAndApi.redeemCoupon(
          this.$route.params.orgCode,
          {
            coupon_id: this.redeemData.coupon_id,
            verify_code: this.redeemData.verify_code,
            store_code: storeCode,
            item_id: this.redeemData?.item_id ?? null,
            coupon_type: this.redeemData.coupon_type,
            item_name: this.redeemData?.item_name ?? '',
            coupon_code: this.redeemData?.coupon_code ?? '',
          }
        );
        this.$router.push({
          name: "LiffProjAbbottAndCouponRedeemSuccess",
          query: {
            qrcode1: res.data.data.qrcode1,
            qrcode2: res.data.data.qrcode2,
          },
        });
      } catch (e) {
        this.setThanksMessage(e.response.data.message);
        this.$router.push({
          name: "LiffProjAbbottAndCouponRedeemFailed",
        });
      }
    },
  },
};
